import {
    Component,
    HostListener,
    Inject,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterLayoutComponent } from '@layouts/footer/footer-layout.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        HttpClientModule,
        TranslateModule,
        FooterLayoutComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    isSticky: boolean = false;
    showScroll: boolean = false;
    showScrollHeight = 300;
    hideScrollHeight = 10;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private meta: Meta,
        private titleService: Title
    ) {
        this.titleService.setTitle(
            'Jacat Games - Free Online Games - Play Now!'
        );
        this.meta.addTags([
            {
                name: 'description',
                content:
                    'Discover a world of entertainment with Jacat Games, a leading game publishing company specializing in puzzle, casual, simulation, and hyper-casual games for both Android and iOS platforms. Dive into our diverse collection of mobile games, designed to captivate and challenge players of all ages',
            },
            {
                property: 'og:description',
                content:
                    'Discover a world of entertainment with Jacat Games, a leading game publishing company specializing in puzzle, casual, simulation, and hyper-casual games for both Android and iOS platforms. Dive into our diverse collection of mobile games, designed to captivate and challenge players of all ages',
            },
            {
                property: 'og:url',
                content: 'https://jacat.games',
            },
            {
                name: 'url',
                content: 'https://jacat.games',
            },
            {
                property: 'og:image',
                content:
                    'https://cdn-uploader.volio.vn/data/store/2024-07-25/1721877993_Ball_master.png',
            },
            {
                name: 'image',
                content:
                    'https://cdn-uploader.volio.vn/data/store/2024-07-25/1721877993_Ball_master.png',
            },
            {
                name: 'keywords',
                content:
                    'Mobile games, puzzle games, casual games, simulation games, hyper-casual games, Android games, iOS games, game development, game publishing, Jacat Games, mobile gaming, engaging games, fun mobile experiences, jacat games studio,mobile game, developer, unity, unity developer',
            },
            {
                name: 'robots',
                content: 'noindex, nofollow',
            },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1.0',
            },
            { charset: 'UTF-8' },
        ]);
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // AOS.init({ easing: 'linear', once: true, disable: 'mobile' });
        }
    }

    @HostListener('window:scroll')
    checkScroll() {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        if (scrollPosition >= this.showScrollHeight) {
            this.showScroll = true;
        } else {
            this.showScroll = false;
        }

        this.isSticky = window.pageYOffset > 0;
    }

    scrollToTop() {
        (function smoothscroll() {
            var currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 5);
            }
        })();
    }
}
