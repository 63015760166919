import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, throwError, timeout } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.headers.has('Error-Handler')) {
            const headers = req.headers.delete('Error-Handler');
            const clonedRequest = req.clone({ headers });
            return next.handle(clonedRequest);
        }
        const newReq = req.clone({});
        return next.handle(newReq).pipe(
            timeout(20000),
            catchError((x) => this.handleAuthError(x))
        );
    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        console.log('err', err);

        if (err.status === 400) {
            this.router.navigateByUrl(`/404`);
            return of(err.message);
        }

        return throwError(err);
    }
}
