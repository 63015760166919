import {
    ApplicationConfig,
    importProvidersFrom,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    PreloadAllModules,
    TitleStrategy,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';

import { routes } from './config/routes/app.routes';
import {
    BrowserModule,
    provideClientHydration,
    withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule,
    provideAnimations,
} from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withFetch,
} from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { HttpLoaderFactory } from './utils/http-loader-factory';
import { CORE_PROVIDERS } from '@config/core.module';
import { provideLottieOptions } from 'ngx-lottie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from '@services/global/interceptor.service';

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'enabled', // set là top thì khi back lại nó sẽ scroll lên đầu page, enabled thì back lại giữ nguyên vị trí cũ
    anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom([
            BrowserModule,
            BrowserAnimationsModule,
            NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
        ]),
        provideRouter(routes, inMemoryScrollingFeature),
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
            })
        ),
        provideAnimations(),
        provideHttpClient(withFetch()),
        provideToastr(),
        provideExperimentalZonelessChangeDetection(),
        CORE_PROVIDERS,
        importProvidersFrom([
            BrowserModule,
            BrowserAnimationsModule,
            // TranslateModule.forRoot({
            //     loader: {
            //         provide: TranslateLoader,
            //         useFactory: HttpLoaderFactory,
            //         deps: [HttpClient],
            //     },
            // }),
        ]),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
        // provideLottieOptions(),
    ],
};
