<div
    class="xl:h-screen lg:h-[850px] md:h-[700px] max-md:h-[500px] w-full main-error flex items-center justify-center"
>
    <div
        class="w-1/2 max-sm:w-3/4 relative rounded-[50px] xl:h-[609px] lg:h-[430px] md:h-[290px] max-md:h-[240px] bg-white flex items-center justify-center"
        style="box-shadow: 0px 4px 17.2px 0px #00000026"
    >
        <img
            src="assets/image/error-bg.png"
            class="w-3/4 h-3/4 object-contain"
            alt=""
        />
        <div
            class="absolute w-full h-full justify-center top-0 left-0 flex flex-col items-center"
        >
            <div
                class="2xl:text-[48px] xl:text-[36px] lg:text-[22px] sm:text-[18px] font-normal uppercase"
            >
                Error
            </div>
            <div
                class="2xl:text-[96px] xl:text-[78px] lg:text-[60px] sm:text-[30px] font-semibold"
            >
                404
            </div>
        </div>
    </div>
</div>
