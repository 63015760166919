import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'select-language-component',
    templateUrl: 'select-language.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule],
})
export class SelectLanguageComponent implements OnInit {
    @Input() showLanguage = false;
    eventLanguage = output<string>();
    constructor() {}

    ngOnInit() {}
    chooseLanguage(code: string) {
        localStorage.setItem('language', code);
        this.eventLanguage.emit(code);
    }
}
