<div
    class="absolute bottom-10 h-[300px] right-10 min-w-[400px] max-lg:hidden"
    *ngIf="showLanguage"
    (mouseleave)="this.showLanguage = false"
    (mouseenter)="this.showLanguage = true"
>
    <div class="relative z-20">
        <div
            class="box-shadow-common px-8 py-10 bg-white rounded-[25px] float-right"
        >
            <div class="text-black font-bold text-[24px]">
                Select your language
            </div>
            <div class="w-full grid grid-cols-2 mt-6 gap-x-1">
                <div
                    (click)="chooseLanguage('en')"
                    class="flex gap-x-2 items-center text-[16px] text-[#00A3FF]"
                >
                    <img src="assets/image/icons/uk.png" alt="" />
                    English
                </div>
                <!-- <div
                    (click)="chooseLanguage('vi')"
                    class="flex gap-x-2 items-center text-[16px] text-[#00A3FF]"
                >
                    <img src="assets/image/icons/vietnam.png" alt="" />
                    Vietnamese
                </div> -->
            </div>
        </div>
        <div
            class="rounded-full h-[55px] w-[55px] bg-white absolute shadow right-10 top-36 z-10"
        ></div>
        <div
            class="rounded-full h-[39px] w-[39px] bg-white absolute box-shadow-common right-3 top-52 z-10"
        ></div>
    </div>
</div>
