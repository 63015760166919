import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-error',
    templateUrl: 'error.component.html',
    styleUrl: 'error.component.scss',
    standalone: true,
    imports: [CommonModule, FormsModule],
})
export class ErrorComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
