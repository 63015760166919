import { Routes } from '@angular/router';
import { ErrorComponent } from '@pages/error/error.component';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () =>
            import('../../pages/home/home.component').then(
                (m) => m.HomeComponent
            ),
        title: 'Home',
    },
    {
        path: 'categories',
        children: [
            {
                path: ':id',
                loadComponent: () =>
                    import('../../pages/categories/categories.component').then(
                        (m) => m.CategoriesComponent
                    ),
            },
        ],
        title: 'Categories - Jacat Games',
    },
    {
        path: 'about',
        loadComponent: () =>
            import('../../pages/about/about.component').then(
                (m) => m.AboutComponent
            ),
    },
    {
        path: 'policy-statement',
        loadComponent: () =>
            import('../../pages/policy/policy.component').then(
                (m) => m.PolicyComponent
            ),
        title: 'Policy Statement - Jacat Games',
    },
    {
        path: 'cookie-statement',
        loadComponent: () =>
            import(
                '../../pages/cookie-statement/cookie-statement.component'
            ).then((m) => m.CookieStatementComponent),
        title: 'Cookie Statement - Jacat Games',
    },
    {
        path: 'contact',
        loadComponent: () =>
            import('../../pages/contact/contact.component').then(
                (m) => m.ContactComponent
            ),
        title: 'Contact - Jacat Games',
    },
    {
        path: 'faq',
        loadComponent: () =>
            import('../../pages/faq/faq.component').then((m) => m.FAQComponent),
        title: 'FAQ - Jacat Games',
    },
    {
        path: ':slug',
        loadComponent: () =>
            import('../../pages/detail-game/detail-game.component').then(
                (m) => m.DetailGameComponent
            ),
        title: 'Game Detail',
    },
    {
        path: '**',
        component: ErrorComponent,
        title: 'Không tìm thấy đường dẫn',
    },
];
