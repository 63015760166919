import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectLanguageComponent } from '@shared/components/select-language/select-language.component';

@Component({
    selector: 'footer-layout-component',
    templateUrl: 'footer-layout.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, SelectLanguageComponent, RouterModule],
    styleUrl: 'footer-layout.component.scss',
})
export class FooterLayoutComponent implements OnInit {
    showLanguage: boolean = false;
    code: string = '';
    constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

    ngOnInit() {
        if (isPlatformBrowser(this._platformId)) {
            this.code = localStorage.getItem('language') ?? '';
        }
    }
    eventLanguage(value: string) {
        this.code = value;
    }
}
