<div
    class="py-16 w-full bg-bgFooter xl:px-[10%] lg:px-[7%] flex justify-between max-lg:flex-col max-lg:items-center max-lg:py-6 max-lg:gap-y-3"
>
    <div
        class="flex lg:flex-col lg:items-center flex-row cursor-pointer"
        [routerLink]="''"
    >
        <img
            src="assets/image/logo.png"
            alt=""
            class="max-lg:w-12 max-lg:h-12 object-contain"
        />
        <img
            src="assets/image/jacat-games.png"
            alt=""
            class="max-lg:w-14 max-lg:h-14 object-contain"
        />
    </div>
    <div
        class="items-center underline gap-x-2 max-sm:text-[9px] hidden max-lg:flex max-lg:text-[12px]"
    >
        <div [routerLink]="'/about'">About</div>
        <div [routerLink]="'/policy-statement'">Privacy Statement</div>
        <div [routerLink]="'/contact'">Contact</div>
        <div>Terms of Use</div>
        <div [routerLink]="'/cookie-statement'">Cookie Statement</div>
        <div>Jobs</div>
        <div [routerLink]="'/faq'">FAQ</div>
    </div>
    <div class="max-lg:hidden">
        <ul class="pl-3 list-disc h-full flex flex-col justify-between">
            <li [routerLink]="'/about'">About</li>
            <li [routerLink]="'/policy-statement'">Privacy Statement</li>
            <li [routerLink]="'/contact'">Contact</li>
        </ul>
    </div>
    <div class="max-lg:hidden">
        <ul class="pl-3 list-disc h-full flex flex-col justify-between">
            <li>Terms of Use</li>
            <li [routerLink]="'/cookie-statement'">Cookie Statement</li>
            <div class="h-5"></div>
        </ul>
    </div>
    <div class="max-lg:hidden">
        <ul class="pl-3 list-disc">
            <li>Jobs</li>
        </ul>
    </div>
    <div class="max-lg:hidden">
        <ul class="pl-3 list-disc">
            <li [routerLink]="'/faq'">FAQ</li>
        </ul>
    </div>
    <div
        class="flex items-center max-lg:mt-2 cursor-pointer relative"
        (mouseleave)="this.showLanguage = false"
        (mouseenter)="this.showLanguage = true"
    >
        <select-language-component
            [showLanguage]="showLanguage"
            (eventLanguage)="eventLanguage($event)"
        ></select-language-component>
        <!-- src=" {{
            code === 'en'
                ? 'assets/image/icons/uk.png'
                : 'assets/image/icons/vietnam.png'
        }}" -->
        <img
            src="assets/image/icons/uk.png"
            alt=""
            class="w-10 h-10 max-lg:w-7 max-lg:h-7"
        />
    </div>
</div>
